<template>
  <div class="tabs tw-flex tw-items-center">
    <div
      class="tabs_item"
      :class="{ active: value === tab.value }"
      v-for="tab in tabs"
      :key="tab.value"
      @click="selectTab(tab.value)">
      <p class="tw-text-14px tw-font-bold">{{tab.label}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      tabs: [
        { label: '服务', value: '1' },
        { label: '需求', value: '2' },
        { label: '科创资源', value: '3' }
      ]
    }
  },
  methods: {
    selectTab (tab) {
      this.$emit('input', tab)
      this.$emit('select-tab', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  height: 48px;
  padding: 0 30px;
  margin-bottom: 10px;
  background: #F7F8F9;
  border-radius: 4px;
  &_item {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    padding: 14px 6px;
    color: #303133;
    &:not(:last-child) {
      margin-right: 120px;
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 8px;
      transform: translateX(-50%);
      width: 0px;
      height: 2px;
      background-color: #3473E6;
      border-radius: 2px;
      transition: all ease 0.3s;
    }
    &:hover, &.active {
      color: #3473E6;
      &::after {
        width: 100%;
      }
    }
  }
}
</style>
