<template>
  <div class="result">
    <div class="result_header tw-flex tw-items-center tw-font-bold tw-text-14px">
      <div
        class="result_header_item tw-flex tw-items-center"
        style="flex: 420px;">
        服务名称
      </div>
      <div
        class="result_header_item tw-flex tw-items-center"
        style="flex: 440px;padding-left:80px;">
        发布用户
      </div>
      <div
        class="result_header_item tw-flex tw-items-center"
        style="flex: 300px;">
        服务类型
      </div>
      <div
        class="result_header_item tw-flex tw-items-center"
        style="flex: 200px;">
        成功成交数
      </div>
      <div
        class="result_header_item tw-flex tw-items-center"
        style="flex: 180px;">
        信用评价
      </div>
    </div>
    <div class="result_body">
      <div
        class="result_body_item item"
        v-for="item in list"
        :key="item.id"
        @click="clickItem(item)">
        <div
          class="item_col col_1"
          style="flex: 420px;">
          <p class="tw-text-16px tw-font-bold">
            {{item.title}}
          </p>
        </div>
        <div
          class="item_col col_2"
          style="flex: 440px;">
          <img
            v-if="item.avatarUrl"
            :src="item.avatarUrl"
            style="object-fit: cover;"
            alt="">
          <img
            v-else
            src="../../../assets/images/default_avatar.png"
            alt="">
          <p class="tw-text-16px tw-font-bold">
            {{item.orgName}}
          </p>
        </div>
        <div
          class="item_col col_3"
          style="flex: 300px;">
          <p class="tw-text-14px">
            {{item.serviceTypeName}}
          </p>
        </div>
        <div
          class="item_col col_4"
          style="flex: 200px;">
          <p class="tw-text-16px tw-font-bold">
            {{item.totalDeal}}
          </p>
        </div>
        <div class="item_col col_5" style="flex: 180px;">
          <p class="tw-text-16px tw-font-bold">
            {{item.avgScore}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    clickItem (item) {
      const { href } = this.$router.resolve({
        path: `/service-detail/${item.id}`
      })
      this.$emit('click', item)

      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  padding: 10px 0;
  &_header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    background: #F7F8F9;
    border-radius: 4px;
    &_item {
      color: #909399;
      height: 100%;
      padding: 0 20px;
    }
  }
  &_body {
    margin-top: 10px;
    &_item {
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 80px;
      background: #FFFFFF;
      border-radius: 4px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      &:hover {
        &::after {
          border-color: #3473E6;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
}

.item {
  margin-bottom: 10px;
  padding: 0 20px;
  display: flex;
  &_col {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .col_1 {
    color: #000000;
  }
  .col_2 {
    justify-content: flex-start;
    & > img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }
    & > p {
      color: #000000;
    }
  }
  .col_3 {
    color: #606266;
  }
  .col_4 {
    color: #F1B221;
  }
  .col_5 {
    color: #3473E6;
  }
  &:hover {
    .col_1, .col_2 > p {
      color: #3473E6;
    }
  }
}
</style>
