<template>
  <div class="panel_condition">
    <condition-list
      label="服务类型"
      v-model="$condition.serviceTypeId"
      :list="serviceTypeList"
      item-label="name"
      item-value="id"
      @change="onServiceTypeChange" />
    <condition-list-divider />
    <condition-list
      v-if="$condition.serviceTypeId"
      label="二级分类"
      v-model="$condition.serviceTypeId2"
      :list="serviceType2List($condition.serviceTypeId)"
      item-label="name"
      item-value="id"
      @change="$emit('search')" />
    <condition-list-divider />
    <condition-list
      label="技术领域"
      v-model="$condition.technicalId"
      :list="technicalList"
      item-label="name"
      item-value="id"
      @change="$emit('search')" />
    <condition-list-divider />
    <condition-list
      label="地区"
      v-model="$condition.province"
      :list="provinceList"
      @change="$emit('search')" />
  </div>
</template>

<script>
import * as api from '@/api'

import conditionListDivider from '../condition-list-divider'
import conditionList from '../condition-list'

import staticData from '@/configs/data'

export default {
  components: {
    conditionListDivider,
    conditionList
  },
  props: {
    condition: Object
  },
  data () {
    return {
      serviceTypeList: [] // 服务类型列表
    }
  },
  computed: {
    $condition: {
      get () {
        return this.condition
      },
      set (val) {
        this.$emit('update:condition', val)
      }
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    serviceType2List () {
      return (id) => {
        let result = []
        this.serviceTypeList.forEach(element => {
          if (element.id === id) {
            result = element.children
          }
        })
        return result
      }
    },
    provinceList () {
      const result = []
      staticData.provinceList.forEach(element => {
        if (element !== '不限') {
          result.push({
            label: element,
            value: element
          })
        }
      })
      return result
    }
  },
  mounted () {
    this.getServiceTypeList()
  },
  methods: {
    getServiceTypeList () {
      if (this.serviceTypeList.length > 0) return
      api.getServiceTypeCheckList().then(res => {
        if (res.data.code === 0) {
          this.serviceTypeList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onServiceTypeChange (id, oldId) {
      this.$emit('search')
      this.$condition.serviceTypeId2 = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.panel_condition{
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
