<template>
  <div class="panel_condition">
    <condition-list
      label="成果种类"
      v-model="$condition.aType"
      :list="achievementTypeList"
      item-label="name"
      item-value="id"
      :show-no-limit="false"
      :show-toggle-more="false"
      @change="$emit('aTypeChange')">
    </condition-list>
    <condition-list-divider />
    <condition-list
      v-if="$condition.aType !== 'cg'"
      label="关键词类型"
      v-model="$condition.type"
      :list="keywordTypeList"
      item-label="name"
      item-value="id"
      :show-no-limit="false"
      :show-toggle-more="false"
      @change="$emit('search')">
    </condition-list>
    <condition-list
      v-if="$condition.aType === 'cg'"
      label="技术领域"
      v-model="$condition.technicalId"
      :list="technicalList"
      item-label="name"
      item-value="id"
      @change="$emit('search')">
    </condition-list>
  </div>
</template>

<script>
import ConditionListDivider from '../condition-list-divider'
import ConditionList from '../condition-list'

export default {
  components: {
    ConditionList,
    ConditionListDivider
  },
  props: {
    condition: Object
  },
  data () {
    return {
      achievementTypeList: [
        { id: 'zl', name: '专利' },
        { id: 'lw', name: '论文' },
        { id: 'cg', name: '成果' }
      ],
      keywordTypeList: [
        { id: 'allContent', name: '全部' },
        { id: 'keyword', name: '关键词' },
        { id: 'title', name: '标题' },
        { id: 'author', name: '作者' }
      ]
    }
  },
  computed: {
    $condition: {
      get () {
        return this.condition
      },
      set (val) {
        this.$emit('update:condition', val)
      }
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.panel_condition{
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
