<template>
  <div class="result">
    <div
      class="item"
      v-for="item in list"
      :key="item.id"
      @click="clickItem(item)">
      <!-- 专利 -->
      <template v-if="type === 'zl'">
        <p class="item_title tw-text-primary-text tw-text-16px tw-font-bold tw-leading-22px">
          <span class="tw-inline-block tw-mr-5px">
            {{ item.no }}
          </span>
          {{ item.title }}
        </p>
        <p class="item_sub tw-text-14px tw-leading-20px">
          <span class="tw-text-regular-text tw-mr-15px">
            [{{item.type}}]
          </span>
          <span class="tw-text-primary tw-mr-10px">
            {{item.applicationPerson}}
          </span>
          <span class="tw-text-primary tw-mr-10px">
            {{item.proclaimDate}}
          </span>
        </p>
        <p class="item_abstract tw-leading-20px tw-text-regular-text">
          {{ item.description }}
        </p>
        <p class="item_keywords tw-text-secondary-text">
          <span
            class="tw-inline-block tw-mr-20px tw-underline  tw-leading-20px"
            v-for="(keyItem, index) in keys(item.key)"
            :key="index">
            {{keyItem}}
          </span>
        </p>
      </template>
      <!-- 论文 -->
      <template v-if="type === 'lw'">
        <p class="item_title tw-text-primary-text tw-text-16px tw-font-bold tw-leading-22px">
          <span class="tw-inline-block tw-mr-5px">
            {{ item.paperNo }}
          </span>
          {{ item.paperTitle }}
        </p>
        <p class="item_sub tw-text-14px tw-leading-20px">
          <span
            class="tw-text-primary tw-mr-10px"
            v-for="(authorItem, index) in authors(item.paperAuthor)"
            :key="index">
            {{authorItem}}
          </span>
          <span v-if="item.column" class="tw-text-primary tw-mr-10px">
            《{{item.column}}》
          </span>
          <span v-if="item.year" class="tw-text-primary">
            {{item.year}}年
          </span>
          <span v-if="item.volume" class="tw-text-primary">
            {{item.volume}}卷
          </span>
          <span v-if="item.phase" class="tw-text-primary">
            {{item.phase}}期
          </span>
        </p>
        <p class="item_abstract tw-leading-20px tw-text-regular-text">
          {{ item.paperAbstract }}
        </p>
        <p class="item_keywords tw-text-secondary-text">
          <span
            class="tw-inline-block tw-mr-20px tw-underline  tw-leading-20px"
            v-for="(keyItem, index) in keys(item.paperKey)"
            :key="index">
            {{keyItem}}
          </span>
        </p>
      </template>
      <!-- 成果 -->
      <template v-if="type === 'cg'">
        <p class="item_title tw-text-primary-text tw-text-16px tw-font-bold tw-leading-22px">
          {{ item.title }}
        </p>
        <p class="item_sub tw-text-14px tw-leading-20px">
          <span class="tw-text-regular-text tw-mr-15px">
            [{{ item.achievementType }}]
          </span>
          <span class="tw-text-primary tw-mr-10px">
            {{ item.publisherName }}
          </span>
        </p>
        <p class="item_abstract tw-leading-20px tw-text-regular-text">
          {{ item.description }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: String,
    keywords: String,
    condition: Object
  },
  data () {
    return {

    }
  },
  computed: {
    keys () {
      return (key = '') => {
        return key.split('%')
      }
    },
    authors () {
      return (author = '') => {
        return author.split('%')
      }
    }
  },
  methods: {
    clickItem (item) {
      const path = {
        zl: `/patent-detail/${item.id}`,
        lw: `/paper-detail/${item.id}`,
        cg: `/achievement-detail/${item.id}`
      }[this.type]
      const { href } = this.$router.resolve({
        path,
        query: {
          type: this.condition.type,
          keywords: this.keywords
        }
      })
      this.$emit('click', item)
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  @apply tw-py-10px;
  .item {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 15px 40px 20px 40px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
      .item_title {
        @apply tw-text-primary;
      }
    }
    &_title {
      padding: 5px 0;
      margin-bottom: 4px;
    }
    &_sub {
      padding: 5px 0;
    }
    &_abstract {
      margin: 10px 0 16px 0;
    }
    &_keywords {

    }
  }
}
</style>
