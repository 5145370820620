<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="资源大厅" />
      <el-input
        class="resource-search-el-input"
        v-model="keywords"
        placeholder="请输入关键词">
        <el-button
          slot="append"
          type="primary"
          icon="el-icon-search"
          @click="search(keywords, tabCondition)">
          搜索
        </el-button>
      </el-input>
    </div>
    <search-tabs v-model="activeTab" @select-tab="selectTab" />

    <template v-if="activeTab === '1'">
      <service-search-condition
        :condition.sync="condition[1]"
        @search="search(keywords, tabCondition)">
      </service-search-condition>
      <service-search-result
        :list="result[1]">
      </service-search-result>
    </template>
    <template v-if="activeTab === '2'">
      <require-search-condition
        :condition.sync="condition[2]"
        @search="search(keywords, tabCondition)">
      </require-search-condition>
      <require-search-result
        :list="result[2]">
      </require-search-result>
    </template>
    <template v-if="activeTab === '3'">
      <achievement-search-condition
        :condition.sync="condition[3]"
        @aTypeChange="aTypeChange">
      </achievement-search-condition>
      <achievement-search-result
        :list="result[3]"
        :type="condition[3].aType"
        :keywords="keywords"
        :condition="condition[3]">
      </achievement-search-result>
    </template>

    <div class="panel_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        :current-page.sync="condition[activeTab].pagination.pageNum"
        :page-size="condition[activeTab].pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="condition[activeTab].pagination.total"
        @current-change="page => search(keywords, tabCondition)">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import SearchTabs from './search-tabs.vue'
import ServiceSearchCondition from './service/service-search-condition.vue'
import ServiceSearchResult from './service/service-search-result.vue'
import RequireSearchCondition from './require/require-search-condition.vue'
import RequireSearchResult from './require/require-search-result.vue'
import AchievementSearchCondition from './achievement/achievement-search-condition.vue'
import AchievementSearchResult from './achievement/achievement-search-result.vue'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    SearchTabs,
    ServiceSearchCondition,
    ServiceSearchResult,
    RequireSearchCondition,
    RequireSearchResult,
    AchievementSearchCondition,
    AchievementSearchResult
  },
  data () {
    return {
      activeTab: '1',
      keywords: '',
      condition: {
        // 服务
        1: {
          serviceTypeId: '',
          serviceTypeId2: '',
          technicalId: '',
          province: '',
          pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0
          }
        },
        // 需求
        2: {
          serviceTypeId: '',
          serviceTypeId2: '',
          technicalId: '',
          pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0
          }
        },
        // 成果
        3: {
          aType: 'cg', // 成果种类
          type: 'allContent',
          technicalId: '', // 技术领域只有在种类为成果时才会选择
          pagination: {
            pageNum: 1,
            pageSize: 10,
            total: 0
          }
        }
      }, // 搜索条件
      result: {
        1: [],
        2: [],
        3: []
      }
    }
  },
  computed: {
    tabCondition () {
      return this.condition[this.activeTab]
    }
  },
  // watch: {
  //   tabCondition: {
  //     immediate: true,
  //     deep: true,
  //     handler: function (val, oldVal) {
  //       this.search(this.keywords, this.tabCondition)
  //     }
  //   }
  // },
  mounted () {
    this.selectTab('1')
  },
  methods: {
    selectTab (tab) {
      this.activeTab = tab
      this.search(this.keywords, this.tabCondition)
    },
    search (keywords, condition) {
      // console.log(this.activeTab, keywords, condition)

      switch (this.activeTab) {
        case '1': // 服务
          this.searchService(keywords, condition)
          break
        case '2': // 需求
          this.searchRequire(keywords, condition)
          break
        case '3': // 成果
          this.searchIpr(keywords, condition)
          break
      }
    },
    // 搜索服务
    searchService (keywords, condition) {
      api.searchService({
        pageNum: condition.pagination.pageNum,
        pageSize: condition.pagination.pageSize,
        keywords,
        serviceTypeId: condition.serviceTypeId,
        serviceTypeId2: condition.serviceTypeId2,
        technicalId: condition.technicalId,
        province: condition.province,
        logicStatus: globalVar.SERVICE_STATUS_1
      }).then(res => {
        if (res.data.code === 0) {
          this.condition[1].pagination.pageNum = res.data.data.currentPage
          this.condition[1].pagination.total = res.data.data.total
          this.result[1] = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 搜索需求
    searchRequire (keywords, condition) {
      api.searchRequire({
        pageNum: condition.pagination.pageNum,
        pageSize: condition.pagination.pageSize,
        keywords,
        serviceTypeId: condition.serviceTypeId,
        serviceTypeId2: condition.serviceTypeId2,
        technicalId: condition.technicalId,
        logicStatus: globalVar.REQUIRE_STATUS_PUBLISHED
      }).then(res => {
        if (res.data.code === 0) {
          this.condition[2].pagination.pageNum = res.data.data.currentPage
          this.condition[2].pagination.total = res.data.data.total
          this.result[2] = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 搜索专利、论文、成果
    async searchIpr (keywords, condition) {
      // 如果搜索类型为成果则不一定需要关键字
      if (condition.aType !== 'cg' && !keywords) {
        this.$message.warning('关键词不能为空')
        return
      }
      let res
      try {
        // 专利
        if (condition.aType === 'zl') {
          res = await api.getPatentList({
            pageNum: condition.pagination.pageNum,
            pageSize: condition.pagination.pageSize,
            keywords,
            type: condition.type
          })
        }
        // 论文
        if (condition.aType === 'lw') {
          res = await api.getJournalList({
            pageNum: condition.pagination.pageNum,
            pageSize: condition.pagination.pageSize,
            keywords,
            type: condition.type
          })
        }
        // 成果
        if (condition.aType === 'cg') {
          res = await api.searchAchievement({
            pageNum: condition.pagination.pageNum,
            pageSize: condition.pagination.pageSize,
            keywords,
            technicalId: condition.technicalId
          })
        }

        if (res.data.code === 0) {
          this.condition[3].pagination.pageNum = res.data.data.currentPage
          this.condition[3].pagination.total = res.data.data.total
          this.result[3] = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 科创资源这一tab会切换专利、论文和成果，切换时要重置数组
    aTypeChange () {
      this.result[3] = []
      this.search(this.keywords, this.tabCondition)
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel {
    width: 100%;
    min-width: 1030px;
    min-height: 100%;
    padding: 0 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    overflow: auto;
    &_header {
      margin: 30px 0 20px 0;
    }
    .img_page {
      display: block;
      cursor: pointer;
    }
    &_condition {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 30px;
      background: #ffffff;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
    }
    &_pagination {
      margin: 20px 0;
      height: 40px;
    }
  }
</style>
