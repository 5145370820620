<template>
  <div class="divider"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #DCDFE6;
}
</style>
